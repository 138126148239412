@import "~styles/globals.scss";
@import 'vendor';
@import 'strange';

* {
  font-family: $font;
  box-sizing: border-box;
}

html {
  background-color: $whitePlain;
  color: $blue;
  font-size: 14px;
  scroll-behavior: smooth;

  > body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;

    &.ReactModal__Body--open {
      overflow: hidden;

      @include lt-medium {
        height: 100%;

        margin: 0;
        padding: 0;
        -webkit-overflow-scrolling: touch;
      }

      .ReactModal__Overlay {
        z-index: 5;
      }
    }
  }
}

*,
button,
input,
optgroup,
select,
textarea {
  font-family: $font;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  font-weight: bold;

  @include lt-desktop {
    br {
      display: none;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  &.left {
    text-align: left;
  }

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }

  &.widthLimit {
    @include widthLimit;
  }
}

h1 {
  @include styleH1;

  &.heroH1 {
    max-width: 485px;

    @include lt-large-desktop {
      max-width: unset;
    }

    &.fullWidth {
      max-width: unset;
      margin: 0; // !important; // stylelint-disable-line declaration-no-important
    }
  }
}

h2 {
  @include styleH1;

  span {
    color: $headingColor;
  }
}

h3 {
  @include styleH2;
}

h4 {
  @include styleH4;
}

h5 {
  @include styleH5;
}

p {
  margin: 0 0 1rem;
  font-size: 1rem;

  @include mobile {
    font-size: 16px;
  }

  &.heroHeadline {
    @include heroHeadline;
  }

  &.textGrey {
    @include textGrey;
  }

  &.textGreySmall {
    @include textGreySmall;
  }

  &.textContent {
    @include textContent;
  }

  &.blockTextAbstract {
    @include blockTextAbstract;
  }

  &.blockTextAbstractDark {
    @include blockTextAbstract;
    color: $blue;
  }

  &.textSub,
  &.textSubPurple {
    @include textSub;
  }

  &.textSubPurple {
    color: $purple;
  }

  // main service classes
  &.column-level {
    margin: 0 0 12px;
    color: $blue;
    font-size: 1.42rem;
    letter-spacing: 0.5px;
    line-height: 1.5;
    text-align: left;

    @include lt-medium {
      text-align: center;
    }
  }

  &.column-abstract {
    margin: 0 0 12px;
    color: $headingColor;
    font-size: 1rem;
    letter-spacing: 1px;
    text-align: left;
    text-transform: uppercase;

    @include lt-medium {
      font-size: 0.86rem;
      text-align: center;
    }
  }

  &.column-text {
    margin: 0 0 22px;
    color: $blue;
    text-align: left;

    @include lt-medium {
      text-align: center;
    }
  }

  &.service-label,
  &.service-label-light {
    @include serviceLabel;
  }

  &.service-label-light {
    color: $headingColor;
  }

  &.service-description {
    margin: 0;
    color: $dGrey;
    font-size: 1.14rem;
    letter-spacing: 0.44px;
    line-height: 1.5;
    text-align: left;

    @include lt-medium {
      text-align: center;
    }

    @include mobile {
      margin: 0 0 25px;
    }
  }
}

ul {
  &.textContent {
    @include textContent;
    padding: 0 0 0 20px;

    li {
      margin: 0 0 7.5px;
    }
  }

  &.textSub,
  &.textSubPurple {
    @include textSub;
  }

  &.textSubPurple {
    color: $purple;
  }
}

img.withShadow {
  @include imageShadow;
}

a {
  text-decoration: none;
  color: $orange;
  cursor: pointer;
}

label {
  display: inline-block;

  &[disabled] {
    cursor: not-allowed;
  }
}

select {
  &[disabled],
  &:disabled {
    cursor: not-allowed;
  }
}

select::-ms-expand {
  display: none;
}

input[type='text'],
input[type='email'],
input[type='tel'],
input[type='number'],
textarea {
  width: 100%;
  height: 54px;
  padding: calc((54px - 32px) / 2);
  border: none;
  border-bottom: 1px solid $inputBorder;
  line-height: 32px;

  &[data-valid='false'] {
    border-width: 2px;
    border-color: $red;

    &:focus {
      border-color: $red;
      outline: none;
      box-shadow: 0 0 10px $red;
    }
  }

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: $placeholderColor;
  }
}

textarea {
  resize: none;
}

input[type='submit'] {
  cursor: pointer;
}

.whiteText {
  color: white;
}

.orangeText {
  color: $solutionsOrange;
}

.customInput {
  label,
  p.label {
    color: $purple;
    font-size: 1.14rem;
    font-weight: bold;
    margin: 0 0 10px;

    @include mobile {
      margin: 0;
    }
  }

  input[type='text'],
  input[type='email'],
  input[type='tel'],
  input[type='number'],
  textarea {
    background-color: transparent;
    font-size: 1.714rem;
    font-weight: bold;
    letter-spacing: 0.8px;
    padding-left: 0;
    padding-right: 0;

    @include mobile {
      font-size: 1.28rem;
      font-weight: normal;
      letter-spacing: 0.6px;
    }

    &[data-valid='false'] {
      border: none;
      border-bottom: 1px solid $red;
      box-shadow: none;

      &:focus {
        border: none;
        border-bottom: 1px solid $red;
        outline: none;
      }
    }

    &::placeholder {
      color: #cfcedd;
    }
  }

  textarea {
    width: 100%;
    line-height: 32px;
    padding: 2px 0;
    height: 100px;
  }
}

.errorContainer {
  text-align: center;
  padding: 50px 0;

  @include mobile {
    padding: 35px 0;
  }
}

.desktopOnly {
  @include mobile {
    display: none;
  }
}

.mobileOnly {
  @include gt-mobile {
    display: none !important; // stylelint-disable-line declaration-no-important
  }
}

.container {
  width: 100%;
  max-width: $containerWidth;
  padding-right: $mobileMargin;
  padding-left: $mobileMargin;

  @include desktop {
    padding-right: $margin;
    padding-left: $margin;
  }

  &.fullMobile {
    @include mobile {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.large-container {
    max-width: $largeContainerWidth;
  }
}

.sectionWrapper {
  width: 100%;
  max-width: $sectionWidth;
  margin: 0 auto;
  overflow: hidden;
  margin-bottom: 1rem;

  &.section-white {
    background-color: white;
  }

  &.section-purple {
    background-color: $blueLight;
  }

  &.section-blue {
    background-color: $blueCorporate;
    padding: 45px 0;

    h1,
    h2,
    h3,
    h4,
    h5,
    p {
      color: white;
    }

    h3 {
      letter-spacing: 0.63px;
      margin: 0 0 10px;
    }

    p {
      font-size: 1.28rem;
      letter-spacing: 0.5px;
      line-height: 1.55;
    }
  }
}

.testimonialsContainer {
  max-width: $sectionWidth;
  padding: 0;
  overflow: hidden;

  @include desktop {
    padding: 0;
  }
}

.section {
  @include sectionStyle;

  @include mobile {
    padding: 40px 0;
  }

  &.section-white {
    background-color: white;
  }

  &.section-purple {
    background-color: $blueLight;
  }

  &.no-padding {
    padding: 0;

    @include tablet {
      padding: 0;
    }

    @include mobile {
      padding: 0;
    }
  }

  &.left {
    text-align: left;
  }

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }

  .section-padding {
    @include sectionPadding;
    padding-bottom: 50px;

    @include tablet {
      padding-bottom: 35px;
    }

    @include mobile {
      padding-bottom: 30px;
    }
  }

  .col-lg-6 {
    .max-width {
      @include large-desktop {
        max-width: 485px;
      }
    }
  }

  h1 {
    @include styleH1;
  }

  h2 {
    @include styleH1;

    @include mobile {
      font-size: 1.75rem;
      letter-spacing: 0.46px;
    }
  }

  h3 {
    @include mobile {
      font-size: 1.5rem;
    }
  }

  p,
  ul,
  ol {
    color: $blue;
    font-size: 1.28rem;
    letter-spacing: 0.5px;
    line-height: 1.5;

    &.sectionHeading {
      @include sectionHeading;
    }

    &.textGrey {
      @include textGrey;
    }

    &.textGreySmall {
      @include textGreySmall;
    }

    &.textContent {
      @include textContent;
    }

    &.blockTextAbstract {
      @include blockTextAbstract;
    }

    &.blockTextAbstractDark {
      @include blockTextAbstract;
      color: $blue;
    }

    &.textSub,
    &.textSubPurple {
      @include textSub;
    }

    &.textSubPurple {
      color: $purple;
    }
  }

  ul,
  ol {
    li {
      margin: 0 0 5px;
    }
  }
}

.childrenAsSlider {
  @include mobile {
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax($slideItemWidth, $slideItemWidth));
    overflow-x: auto;
    grid-auto-flow: column;
    scroll-behavior: smooth;
    display: grid;
    margin: 0 auto;
    grid-gap: 24px;

    // override column padding css
    > * {
      padding-left: 0 !important; // stylelint-disable-line declaration-no-important
      padding-right: 0 !important; // stylelint-disable-line declaration-no-important
      width: $slideItemWidth !important; // stylelint-disable-line declaration-no-important
    }
  }

  &.childrenAsSliderLarge {
    grid-template-columns: repeat(auto-fit, minmax($slideItemLargeWidth, $slideItemLargeWidth));

    @include mobile {
      > * {
        width: $slideItemLargeWidth !important; // stylelint-disable-line declaration-no-important
      }
    }
  }
}

.sectionHeading {
  @include sectionHeading;

  &.asTitle {
    @include styleH4;
    color: $purple;
    text-transform: none;
  }
}

.coursePage {
  > div:not(:first-child) {
    .sectionHeading[id] {
      margin-top: 40px;
    }
  }
}

.sectionTitle {
  @include sectionTitle;
}

.postDate {
  @include sectionHeading;
  margin: 0;
  color: $headingColor;
  font-size: 0.857rem;
  text-transform: unset;
}

.cardsWrapper {
  &:not(.mobileList) {
    @include mobile {
      flex-wrap: nowrap;
      overflow-x: auto;
    }
  }
}

.iconBlock {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: center;
  margin: 0 0 15px;

  + .service-label {
    @include serviceLabel;

    + .service-label-light {
      @include serviceLabel;
      color: $headingColor;
    }
  }
}

.searchForm {
  position: relative;
  margin: 0 0 37px;

  input {
    width: 407px;
    max-width: 100%;
    height: $searchInputHeight;
    padding: 0 25px 0 65px;
    margin: 0!important;

    border: 4px solid $inputBorder;
    border-radius: 30px;
    outline: none;
    background-color: white;
    background-image: url(~images/icons/search-grey.svg);
    background-repeat: no-repeat;
    background-position: 23px 17px;
    background-size: 24px 24px;
    color: $inputColor;
    font-size: 1.43rem;
    font-style: italic;
    letter-spacing: 0.45px;
    line-height: $searchInputHeight - (4px * 2);

    @include mobile {
      border-color: $green;
      font-size: 1.21rem;
      letter-spacing: 0.39px;
    }

    &::placeholder {
      color: $placeholderColor;
    }
  }

  button {

    display: none;
  }
}

#row-section-learn-about-our-covid-19-response {
  height: 0;
  overflow: hidden;
}

// static page banner: contact us
// sample: https://amanaliving.com.au/contact-us, https://amanaliving.com.au/help-centre
.pageBanner {
  background-color: $orangeLight;
  padding: 60px 0 50px;
  text-align: center;
  position: relative;

  h1,
  p {
    position: relative;
    z-index: 1;
  }

  .bannerHeroBackgroundCustom {
    z-index: 0;
  }

  @include mobile {
    padding: 30px 0;
  }

  .sectionHeading {
    margin: 0 0 10px;
    text-align: center;
    color: $orangeDeep;
  }

  h1 {
    margin: 0 0 22px;
    font-size: 3.42rem;
    letter-spacing: 1.33px;

    @include mobile {
      margin: 0 0 15px;
      font-size: 2.14rem;
      letter-spacing: 0.63px;
    }
  }

  h5 {
    @include styleH4;
  }

  p {
    color: $blueDeep;
    font-size: 1.57rem;
    line-height: 1.5;
    letter-spacing: 0.6px;

    @include mobile {
      font-size: 1.28rem;
      letter-spacing: 0.5px;
    }
  }
}

// article pages: news, event, information detail, teams page on careers
.articleBody,
.teamBody {
  max-width: 800px;
  margin: 0 auto;

  @include lt-desktop {
    max-width: 100%;
  }

  > div {
    text-align: left;

    h5 {
      @include styleH4;
      margin: 1.5rem 0 1rem;
    }

    p,
    ul,
    ol {
      @include styleH4;
      text-align: left;

      &.blockTextAbstract {
        @include blockTextAbstract;
      }

      &.blockTextAbstractDark {
        @include blockTextAbstract;
        color: $blue;
      }

      &.textGreySmall {
        @include textGreySmall;
        color: $blue;
      }

      &.textContent {
        @include textContent;
      }

      &.textSub {
        @include textSub;
        color: $blue;
      }
    }

    ul,
    ol {
      padding-left: 30px;

      li {
        margin-bottom: 5px;
      }
    }

    a {
      color: $orange;
    }

    img {
      width: 100%;

      @include mobile {
        width: 300px;
      }
    }

    figure {
      margin: 0 0 24px;
      text-align: center;

      iframe {
        max-width: 100%;

        @include mobile {
          width: 100%;
        }
      }
    }

    > figure {
      &.iframe {
        display: block;
        position: relative;
        width: 100%;
        height: 0;
        padding-top: 66.66%;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }

      img {
        width: 100%;
      }
    }

    figcaption {
      font-size: 1rem;
      color: $headingColor;
      font-weight: bold;
      text-align: left;
    }

    table {
      margin: 25px 0 30px;

      @include large-desktop {
        margin-left: -200px;
        margin-right: -200px;
      }

      @include lt-desktop {
        display: block;

        tbody {
          display: block;
        }

        tr {
          display: grid;
          grid-template-columns: unset;
          grid-auto-flow: column;
          grid-gap: 15px;
          overflow-x: auto;

          td {
            width: 100%;
          }
        }
      }

      figure {
        margin: 0;
        padding: 0 15px;

        @include lt-desktop {
          padding: 0;
        }

        + br {
          display: none;
        }
      }
    }
  }
}

// show background image as section on mobile
.mobileBackground {
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 175% auto;
  width: 100%;
  height: 0;
  padding: 77% 0 0 0;

  @include medium {
    display: none;
  }

  img {
    display: none;
  }
}

.mobileBackgroundFixed {
  background-image: none !important; // stylelint-disable-line declaration-no-important
  padding: 0 !important; // stylelint-disable-line declaration-no-important

  @include medium {
    display: none;
  }

  img {
    display: block;
    width: 100%;
  }
}

// services block input by cms
// sample: aged care services page
.services {
  @include serviceBlock;
  margin-bottom: 24px;

  @include mobile {
    margin-bottom: 0;
  }

  &.purple {
    @include serviceBlockStyle($blueDeep, $blueLight);
  }

  &.green {
    @include serviceBlockStyle($tealDeep, $tealLight);
  }

  &.orange {
    @include serviceBlockStyle($stoneDeep, $stoneLight);
  }

  h3 {
    margin: 0 0 15px;
    font-size: 1.71rem;
    letter-spacing: 0.5px;
    line-height: 1.5;

    @include mobile {
      font-size: 1.28rem;
      letter-spacing: 0.45px;
      line-height: 1.3;
    }
  }
}

////////////////////////
// service globals block
////////////////////////
// main services section, service globals on retirement villages and nursing homes
// sample: https://amanaliving.com.au/home-care/how-is-home-care-funded/home-care-packages
.col-12.column-borders {
  &.column-without-padding {
    > .row > .col-lg-6 {
      @include large-desktop {
        &:nth-child(2n) {
          padding-left: 45px;
          padding-right: 12px;
        }

        &:nth-child(2n + 1) {
          padding-left: 12px;
          padding-right: 45px;
        }
      }
    }
  }

  > .row > .col-lg-6 {
    padding-top: 25px;
    padding-bottom: 25px;

    @include large-desktop {
      &:nth-child(2n) {
        padding-left: 45px;
        padding-right: 50px + 12px;
      }

      &:nth-child(2n + 1) {
        padding-left: 95px;
      }
    }

    + .col-lg-6 {
      @include lt-medium {
        border-top: 1px solid $inputBorder;
      }

      @include medium {
        border-left: 1px solid $inputBorder;
      }

      + .col-lg-6 {
        padding-top: 45px;
        border-top: 1px solid $inputBorder;
        border-left: none;

        + .col-lg-6 {
          @include medium {
            border-left: 1px solid $inputBorder;
          }
        }
      }
    }

    .row .col-lg-3 {
      @include lt-medium {
        flex: 0 0 33.33%;
        max-width: 33.33%;
      }
    }

    .row {
      @include lt-medium {
        justify-content: center;
      }
    }
  }

  + .col-12 {
    &::before {
      display: block;
      position: relative;
      width: 100%;
      height: 1px;
      margin: 20px 0 25px;
      background-color: $inputBorder;
      content: '';
    }
  }
}

// 2 columns content with border
// sample: https://amanaliving.com.au/about-us
.col-lg-6.column-borders {
  @include large-desktop {
    &:nth-child(1) {
      padding-right: 40px;
    }

    &:nth-child(2) {
      border-left: 1px solid $inputBorder;
      padding-left: 40px;
    }
  }
}

// 3 columns with border: about-us, covid page
// sample: https://amanaliving.com.au/about-us or https://amanaliving.com.au/covid-19-update
.col-lg-4.column-borders {
  padding-top: 40px;
  padding-bottom: 50px !important; // stylelint-disable-line declaration-no-important

  @include mobile {
    padding-top: 20px;
    padding-bottom: 20px !important; // stylelint-disable-line declaration-no-important
  }

  &:nth-child(3n + 1) {
    border-top: 1px solid $inputBorder;

    @include desktop {
      padding-left: 12px !important; // stylelint-disable-line declaration-no-important
    }

    @include mobile {
      border-top: none;
    }
  }

  &:nth-child(3n + 2) {
    border-top: 1px solid $inputBorder;
    border-left: 1px solid $inputBorder;

    @include mobile {
      border-top: none;
      border-left: none;
    }
  }

  &:nth-child(3n) {
    border-top: 1px solid $inputBorder;
    border-left: 1px solid $inputBorder;

    @include desktop {
      padding-right: 12px !important; // stylelint-disable-line declaration-no-important
    }

    @include mobile {
      border-top: none;
      border-left: none;
    }
  }

  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3) {
    border-top: none;
  }

  h5 {
    color: $purple;
  }

  a {
    color: $purple;
    font-size: 1.14rem;
    height: 30px;
    line-height: 30px;
    margin: 0;

    > svg {
      path {
        fill: $purple;
        stroke: $purple;
      }
    }
  }
}

// 4 columns with border: home career page
// sample: https://amanaliving.com.au/about-us or https://amanaliving.com.au/covid-19-update
.col-lg-3.column-borders {
  @include medium {
    border-left: 1px solid $inputBorder;
  }

  &:first-child {
    border-left: none;
  }
}

///////////////////////////
// home care services block
///////////////////////////
// home care services block + fill column option
.col-lg-6.fillColumn {
  &:nth-child(1) {
    padding-top: 85px;
  }

  &:nth-child(2) {
    padding: 0;
  }
}

//////////////////////////////////////
// set max width content on some parts
//////////////////////////////////////
.rowRoot {
  > .sectionHeading:nth-child(1) + .col-lg-6:nth-child(2),
  > .col-lg-6:nth-child(1) {
    > div[class^='styles_textBlock'] {
      @include large-desktop {
        max-width: 500px;
      }
    }
  }

  // sample: https://amanaliving.com.au/home-care/how-is-home-care-funded/commonwealth-home-support-programme-chsp
  > .col-lg-8.col-cta-included {
    > * {
      @include large-desktop {
        max-width: $textWidthLimit;
      }
    }
  }

  > .col-cta-included {
    > div[class^='styles_textBlock'] {
      @include large-desktop {
        max-width: $textWidthLimit;
      }
    }
  }

  > .col-lg-6 {
    // set buttons same width
    // sample: career hompage
    > .sectionHeading + div[class^='styles_textBlock'] ~ a[type='button'],
    > .sectionHeading + div[class^='styles_textBlock'] ~ button[class^='styles_button'] {
      @include medium {
        min-width: 375px;
      }
    }

    // reduce space between buttons
    // sample: https://amanaliving.com.au/retirement-villages
    > .row {
      > .col-lg-6 {
        @include medium {
          &:nth-child(2n) {
            padding-left: 6px;
          }

          &:nth-child(2n + 1) {
            padding-right: 6px;
          }
        }

        > button {
          width: 100%;
        }
      }
    }
  }
}

// sample: https://amanaliving.com.au/about-us
.flexibleColumns {
  display: flex;

  @include large-desktop {
    > div {
      flex: 1 1 auto;
    }
  }

  @include lt-desktop {
    flex-wrap: wrap;
  }

  > div {
    &:first-child {
      * {
        max-width: 800px;
      }
    }

    @include lt-desktop {
      width: 100%;
    }
  }
}

// reduce padding between 2 buttons columns on large screen only
// sample: https://amanaliving.com.au/home-care
.col-lg-6.col-lg-buttons {
  @include medium {
    &:nth-child(2n) {
      padding-left: 6px;
    }

    &:nth-child(2n + 1) {
      padding-right: 6px;
    }
  }
}

.textIconBlock {
  display: flex;
  padding: 50px 0 30px;
  height: 100%;

  figure {
    margin: 0;
    min-width: 100px;
    text-align: center;
  }

  p,
  ul {
    font-size: 1.14rem;
  }

  ul {
    padding: 0 0 0 20px;

    li {
      margin: 0 0 5px;
    }
  }

  &.trainingText {
    border: none;

    @include mobile {
      padding: 40px 0 20px;
    }

    > div {
      display: flex;
      justify-content: center;
      margin: auto 0;

      h4 {
        @include styleH2;
        margin: 0;

        @include mobile {
          @include styleH3;
        }
      }
    }

    .figure {
      min-width: 80px;
    }
  }
}

// PageUp template
#jobs {
  position: relative;

  a.button.job-mail-subscribe-link {
    position: absolute;
    top: -190px;
    right: 0;
    z-index: 2;

    border-radius: 0!important;
    color: $whitePlain!important;
    background: $orangeActive!important;
    max-width: max-content!important;
    width: 100%!important;
    padding: 1rem 3rem 1rem 1.75rem!important;
    min-width: unset!important;
    transition: all 300ms ease-in-out!important;
    border: $orangeActive 1px solid!important;
    line-height: 1.25!important;
    margin: 0!important;
    font-size: 1.28rem!important;
    font-weight: bold!important;
    letter-spacing: 0.5px!important;
    text-decoration: none!important;

    * {
      margin: 0!important;
      line-height: 1.25!important;
      font-size: 1.28rem!important;
      font-weight: bold!important;
      letter-spacing: 0.5px!important;
      text-decoration: none!important;
      color: $whitePlain!important;
    }

    img {
      display: none!important;
    }

    &:after {
      content: ''!important;
      display: block!important;
      position: absolute!important;
      right: 0.5rem!important;
      top: 50%!important;
      transform: translate3d(0,-50%,0)!important;
      width: 1.35rem!important;
      height: 1.35rem!important;
      background-size: contain!important;
      background-repeat: no-repeat!important;
      transition: transform 300ms ease-in-out!important;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCAxMiAyOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wLjYzMzcxNSAwLjMwNzE4NkMxLjMyNDI4IC0wLjIxMDg2NyAyLjI5NDYxIC0wLjA1ODE1MjMgMi44MDEwMiAwLjY0ODI4NEwxMS42OTk4IDEzLjA2MkMxMi4xMDAxIDEzLjYyMDMgMTIuMTAwMSAxNC4zNzk3IDExLjY5OTggMTQuOTM4TDIuODAxMDIgMjcuMzUxN0MyLjI5NDYxIDI4LjA1ODIgMS4zMjQyOCAyOC4yMTA5IDAuNjMzNzE1IDI3LjY5MjhDLTAuMDU2ODQ1NCAyNy4xNzQ4IC0wLjIwNjEyOCAyNi4xODIxIDAuMzAwMjgzIDI1LjQ3NTdMOC41MjY2NiAxNEwwLjMwMDI4MyAyLjUyNDMyQy0wLjIwNjEyOCAxLjgxNzg4IC0wLjA1Njg0NTQgMC44MjUyMzkgMC42MzM3MTUgMC4zMDcxODZaIiBmaWxsPSIjREM1ODJFIi8+Cjwvc3ZnPgo=")!important;
      filter: brightness(100);
    }

    svg {
      display: none!important;
    }
    &:hover {
      background: $orangeDeep!important;
      &:after {
        transform: translate3d(.25rem,-50%,0)!important;
      }
    }
    &:active {
      transition: transform 50ms ease-in-out!important;
      transform: translate3d(0,2px,0)!important;
    }

    &.newStyleButtonOverrideWhite {
      color: $whitePlain!important;
      background: #FFFFFF00!important;
      border: $whitePlain 1px solid!important;

      &:after {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCAxMiAyOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wLjYzMzcxNSAwLjMwNzE4NkMxLjMyNDI4IC0wLjIxMDg2NyAyLjI5NDYxIC0wLjA1ODE1MjMgMi44MDEwMiAwLjY0ODI4NEwxMS42OTk4IDEzLjA2MkMxMi4xMDAxIDEzLjYyMDMgMTIuMTAwMSAxNC4zNzk3IDExLjY5OTggMTQuOTM4TDIuODAxMDIgMjcuMzUxN0MyLjI5NDYxIDI4LjA1ODIgMS4zMjQyOCAyOC4yMTA5IDAuNjMzNzE1IDI3LjY5MjhDLTAuMDU2ODQ1NCAyNy4xNzQ4IC0wLjIwNjEyOCAyNi4xODIxIDAuMzAwMjgzIDI1LjQ3NTdMOC41MjY2NiAxNEwwLjMwMDI4MyAyLjUyNDMyQy0wLjIwNjEyOCAxLjgxNzg4IC0wLjA1Njg0NTQgMC44MjUyMzkgMC42MzM3MTUgMC4zMDcxODZaIiBmaWxsPSIjRkZGIi8+Cjwvc3ZnPgo=")!important;
      }

      &:hover {
        background: #FFFFFF10!important;
      }
    }
  }

  #jobs-form {
    .filters a.button,
    a.back-link.button,
    a.employee-referral-link.button,
    a.apply-link.button,
    .job-listing-wrapper > a {
      border-radius: 0!important;
      color: $whitePlain!important;
      background: $orangeActive!important;
      max-width: max-content!important;
      width: 100%!important;
      padding: 1rem 3rem 1rem 1.75rem!important;
      min-width: unset!important;
      transition: all 300ms ease-in-out!important;
      position: relative!important;
      border: $orangeActive 1px solid!important;
      line-height: 1.25!important;
      margin: 0!important;
      font-size: 1.28rem!important;
      font-weight: bold!important;
      letter-spacing: 0.5px!important;
      text-decoration: none!important;

      * {
        margin: 0!important;
        line-height: 1.25!important;
        font-size: 1.28rem!important;
        font-weight: bold!important;
        letter-spacing: 0.5px!important;
        text-decoration: none!important;
        color: $whitePlain!important;
      }

      img {
        display: none!important;
      }

      &:after {
        content: ''!important;
        display: block!important;
        position: absolute!important;
        right: 0.5rem!important;
        top: 50%!important;
        transform: translate3d(0,-50%,0)!important;
        width: 1.35rem!important;
        height: 1.35rem!important;
        background-size: contain!important;
        background-repeat: no-repeat!important;
        transition: transform 300ms ease-in-out!important;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCAxMiAyOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wLjYzMzcxNSAwLjMwNzE4NkMxLjMyNDI4IC0wLjIxMDg2NyAyLjI5NDYxIC0wLjA1ODE1MjMgMi44MDEwMiAwLjY0ODI4NEwxMS42OTk4IDEzLjA2MkMxMi4xMDAxIDEzLjYyMDMgMTIuMTAwMSAxNC4zNzk3IDExLjY5OTggMTQuOTM4TDIuODAxMDIgMjcuMzUxN0MyLjI5NDYxIDI4LjA1ODIgMS4zMjQyOCAyOC4yMTA5IDAuNjMzNzE1IDI3LjY5MjhDLTAuMDU2ODQ1NCAyNy4xNzQ4IC0wLjIwNjEyOCAyNi4xODIxIDAuMzAwMjgzIDI1LjQ3NTdMOC41MjY2NiAxNEwwLjMwMDI4MyAyLjUyNDMyQy0wLjIwNjEyOCAxLjgxNzg4IC0wLjA1Njg0NTQgMC44MjUyMzkgMC42MzM3MTUgMC4zMDcxODZaIiBmaWxsPSIjREM1ODJFIi8+Cjwvc3ZnPgo=")!important;
        filter: brightness(100);
      }

      svg {
        display: none!important;
      }
      &:hover {
        background: $orangeDeep!important;
        &:after {
          transform: translate3d(.25rem,-50%,0)!important;
        }
      }
      &:active {
        transition: transform 50ms ease-in-out!important;
        transform: translate3d(0,2px,0)!important;
      }

      &.newStyleButtonOverrideWhite {
        color: $whitePlain!important;
        background: #FFFFFF00!important;
        border: $whitePlain 1px solid!important;

        &:after {
          background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCAxMiAyOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wLjYzMzcxNSAwLjMwNzE4NkMxLjMyNDI4IC0wLjIxMDg2NyAyLjI5NDYxIC0wLjA1ODE1MjMgMi44MDEwMiAwLjY0ODI4NEwxMS42OTk4IDEzLjA2MkMxMi4xMDAxIDEzLjYyMDMgMTIuMTAwMSAxNC4zNzk3IDExLjY5OTggMTQuOTM4TDIuODAxMDIgMjcuMzUxN0MyLjI5NDYxIDI4LjA1ODIgMS4zMjQyOCAyOC4yMTA5IDAuNjMzNzE1IDI3LjY5MjhDLTAuMDU2ODQ1NCAyNy4xNzQ4IC0wLjIwNjEyOCAyNi4xODIxIDAuMzAwMjgzIDI1LjQ3NTdMOC41MjY2NiAxNEwwLjMwMDI4MyAyLjUyNDMyQy0wLjIwNjEyOCAxLjgxNzg4IC0wLjA1Njg0NTQgMC44MjUyMzkgMC42MzM3MTUgMC4zMDcxODZaIiBmaWxsPSIjRkZGIi8+Cjwvc3ZnPgo=")!important;
        }

        &:hover {
          background: #FFFFFF10!important;
        }
      }
    }
  }
}

.jobs {
  display: flex;
  flex-direction: row;

  @include mobile {
    flex-direction: column;
  }

  .filters {
    width: 400px;
    min-width: 400px;
    padding: 0 25px 0 0;

    @include mobile {
      padding: 0;
      width: 100%;
      min-width: unset;
    }

    > div {
      margin: 30px 0;
      border: 1px solid $blue;

      .filtersHeading {
        background-color: $blue;
        color: white;
        padding: 15px;
        font-size: 1.42rem;
        font-weight: bold;
      }

      #search-filters {
        padding: 15px;
      }
    }

    h3 {
      color: $headingColor;
      font-size: 1.14rem;
      font-weight: bold;
      letter-spacing: 0.35px;
      line-height: 1.375;
    }

    .filter-group .filter-group-title {
      color: $headingColor;
      font-size: 1rem;
      font-weight: bold;
      margin: 0 0 12px;
    }

    ul.filter,
    ul.filter span {
      color: $headingColor;
      font-size: 1.14rem;
      font-weight: bold;
      letter-spacing: 0.35px;
    }

    ul.filter {
      li {
        padding: 0;
        margin: 0 0 5px;

        input[type='checkbox'] {
          margin: 5px 5px 0 0;
        }
      }
    }
  }

  .listing {
    h2 {
      font-size: 1.57rem;
    }

    .search {
      border: 1px solid $inputBorder;
      border-radius: 9px;
      background: white;
      padding: 40px 25px 15px;
      margin: 0 0 24px;

      @include mobile {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    #search-keyword {
      box-shadow: none;
      border: 2px solid $inputBorder;
      background: white;
      border-radius: 30px;
      width: 100%;
      padding: 0 20px;
      height: 48px;
      line-height: 44px;
      font-size: 1.28rem;
      letter-spacing: 0.4px;
      margin: 0 0 24px;
    }

    #recent-jobs {
      #campaignsDiv {
        border: 1px solid $bannerBg;
        background: white;
        border-radius: 9px;
        padding: 30px 25px 15px;
        margin: 0 0 24px;

        @include mobile {
          padding-left: 15px;
          padding-right: 15px;
        }
      }

      > table {
        background-color: white;
        border-radius: 9px;
        text-align: left;

        thead {
          background-color: $bannerBg;
        }

        tbody {
          td {
            .job-link {
              color: $blue;
              font-weight: bold;
              letter-spacing: 0.5px;
            }

            .location {
              color: $headingColor;
              font-size: 1rem;
              font-weight: bold;
              letter-spacing: 1px;
              display: block;
            }

            .close-date {
              color: $headingColor;
              font-size: 1rem;
              font-weight: bold;
              letter-spacing: 1px;
              display: block;
            }
          }

          tr {
            &:not(.summary) {
              td {
                padding-top: 20px;
              }
            }

            &.summary {
              margin-bottom: 24px;
              padding-bottom: 20px;

              td {
                border-bottom: 1px solid $inputBorder;
                line-height: 1.5;
                letter-spacing: 0.4px;
                padding-top: 20px;
              }
            }

            td {
              vertical-align: top;
            }
          }
        }
      }
    }

    #social-media {
      h2 {
        font-size: 1.14rem;

        span {
          margin-left: 0;
        }
      }

      .addthis_toolbox {
        .addthis_toolbox a.at300b,
        .addthis_toolbox a.at300m {
          display: flex;
        }
      }
    }
  }
}

.feedbackBlock {
  padding-right: 24px;

  @include mobile {
    text-align: center;
    padding-right: 0;
  }
}

p:not(.blockTextAbstract).feedbackBlock {
  font-size: $fontSizeH4;
  letter-spacing: 0.5px;
  line-height: 1.55;

  @include mobile {
    font-size: $fontSizeH4Mobile;
  }
}

.orangeTextPrimary {
  color: $colorPrimaryHighlight;
}
