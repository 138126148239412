@import url("https://use.typekit.net/oqk8dlf.css");

html {
  * {
    letter-spacing: 0!important;
  }
}

.textMimicH2 {
  @include styleH1;
}

.textMimicH3 {
  @include styleH2;
}

.gelicaFont {
  font-family: "gelica", times, sans-serif!important;
}

.testimonialsContainer {
  overflow: unset!important;

  .react-multi-carousel-list {
    overflow: unset!important;
  }
  * {
    color: $blueDeep;
  }

  button {
    color: $blueDeep;
    cursor: pointer;
  }

  @media only screen and (min-width: 1024px) and (max-width: 1399px) {
    .react-multi-carousel-item {
      opacity: .25;
      transition: opacity 150ms ease-in-out;
    }
    .react-multi-carousel-item--active {
      opacity: 1;
      z-index: 1;

      > div {
        max-width: 50vw!important;
        > div {
          width: 100%!important;
        }
      }
    }
  }

  @media only screen and (min-width: 1400px) {
    .react-multi-carousel-track {
      li,
      li * {
        max-width: 100% !important;
      }

      li {
        padding: 0 1rem!important;
      }
    }
    .react-multi-carousel-item--active {
      > div {
        max-width: 100%!important;
        > div {
          width: 100%!important;
        }
      }
    }
  }
}

#additional-services {
  margin-bottom: 1rem;
}

.saSection {
  position: relative;
  padding: 0!important;
  margin: auto!important;
  overflow: hidden;

  .saContainer {
    position: relative;
    padding: 2rem;
    margin: auto;
    max-width: 1600px;

    @media only screen and (min-width: 992px) {
      padding: 5rem 4rem;
    }
  }
}

.bg-blue-active {
  h1,h2,h3,.textMimicH2,.textMimicH3 {
    color: $blueDeep;
  }
}
.bg-orange-active {
  h1,h2,h3,.textMimicH2,.textMimicH3 {
    color: $orangeDeep;
  }
}
.bg-stone-active {
  h1,h2,h3,.textMimicH2,.textMimicH3 {
    color: $stoneDeep;
  }
}
.bg-teal-active {
  h1,h2,h3,.textMimicH2,.textMimicH3 {
    color: $tealDeep;
  }
}
.bg-transparent {
  h1,h2,h3,.textMimicH2,.textMimicH3 {
    color: $orangeDeep;
  }
}
.bg-white {
  h1,h2,h3,.textMimicH2,.textMimicH3 {
    color: $orangeDeep;
  }
}

.bg-blue-signature {
  h1,h2,h3,.textMimicH2,.textMimicH3 {
    color: $blueDeep;
  }
}
.bg-orange-signature {
  h1,h2,h3,.textMimicH2,.textMimicH3 {
    color: $orangeDeep;
  }
}
.bg-stone-signature {
  h1,h2,h3,.textMimicH2,.textMimicH3 {
    color: $stoneDeep;
  }
}
.bg-teal-signature {
  h1,h2,h3,.textMimicH2,.textMimicH3 {
    color: $tealDeep;
  }
}

.newStyleButtonOverrideOutline {
  border-radius: 0!important;
  color: $blueDeep!important;
  background: #DC582E00!important;
  max-width: max-content!important;
  width: 100%!important;
  min-height: 48px!important;
  padding: 1rem 3rem 1rem 1.75rem!important;
  min-width: unset!important;
  transition: all 300ms ease-in-out!important;
  position: relative!important;
  border: $orangeDeep 1px solid!important;
  line-height: 1.25!important;
  margin: 0!important;
  font-size: 1.28rem!important;
  font-weight: bold!important;
  letter-spacing: 0.5px!important;
  text-decoration: none!important;

  * {
    margin: 0!important;
    line-height: 1.25!important;
    font-size: 1.28rem!important;
    font-weight: bold!important;
    letter-spacing: 0.5px!important;
    text-decoration: none!important;
    color: $blueDeep!important;
  }

  img {
    display: none!important;
  }

  &:after {
    content: ''!important;
    display: block!important;
    position: absolute!important;
    right: 0.5rem!important;
    top: 50%!important;
    transform: translate3d(0,-50%,0)!important;
    width: 1.35rem!important;
    height: 1.35rem!important;
    background-size: contain!important;
    background-repeat: no-repeat!important;
    transition: transform 300ms ease-in-out!important;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCAxMiAyOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wLjYzMzcxNSAwLjMwNzE4NkMxLjMyNDI4IC0wLjIxMDg2NyAyLjI5NDYxIC0wLjA1ODE1MjMgMi44MDEwMiAwLjY0ODI4NEwxMS42OTk4IDEzLjA2MkMxMi4xMDAxIDEzLjYyMDMgMTIuMTAwMSAxNC4zNzk3IDExLjY5OTggMTQuOTM4TDIuODAxMDIgMjcuMzUxN0MyLjI5NDYxIDI4LjA1ODIgMS4zMjQyOCAyOC4yMTA5IDAuNjMzNzE1IDI3LjY5MjhDLTAuMDU2ODQ1NCAyNy4xNzQ4IC0wLjIwNjEyOCAyNi4xODIxIDAuMzAwMjgzIDI1LjQ3NTdMOC41MjY2NiAxNEwwLjMwMDI4MyAyLjUyNDMyQy0wLjIwNjEyOCAxLjgxNzg4IC0wLjA1Njg0NTQgMC44MjUyMzkgMC42MzM3MTUgMC4zMDcxODZaIiBmaWxsPSIjREM1ODJFIi8+Cjwvc3ZnPgo=")!important;
  }

  svg {
    display: none!important;
  }
  &:hover {
    background: #DC582E10!important;
    &:after {
      transform: translate3d(.25rem,-50%,0)!important;
    }
  }
  &:active {
    transition: transform 50ms ease-in-out!important;
    transform: translate3d(0,2px,0)!important;
  }

  &.newStyleButtonOverrideWhite {
    color: $whitePlain!important;
    background: #FFFFFF00!important;
    border: $whitePlain 1px solid!important;

    &:after {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCAxMiAyOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wLjYzMzcxNSAwLjMwNzE4NkMxLjMyNDI4IC0wLjIxMDg2NyAyLjI5NDYxIC0wLjA1ODE1MjMgMi44MDEwMiAwLjY0ODI4NEwxMS42OTk4IDEzLjA2MkMxMi4xMDAxIDEzLjYyMDMgMTIuMTAwMSAxNC4zNzk3IDExLjY5OTggMTQuOTM4TDIuODAxMDIgMjcuMzUxN0MyLjI5NDYxIDI4LjA1ODIgMS4zMjQyOCAyOC4yMTA5IDAuNjMzNzE1IDI3LjY5MjhDLTAuMDU2ODQ1NCAyNy4xNzQ4IC0wLjIwNjEyOCAyNi4xODIxIDAuMzAwMjgzIDI1LjQ3NTdMOC41MjY2NiAxNEwwLjMwMDI4MyAyLjUyNDMyQy0wLjIwNjEyOCAxLjgxNzg4IC0wLjA1Njg0NTQgMC44MjUyMzkgMC42MzM3MTUgMC4zMDcxODZaIiBmaWxsPSIjRkZGIi8+Cjwvc3ZnPgo=")!important;
    }

    &:hover {
      background: #FFFFFF10!important;
    }
  }
}

.newStyleButtonOverrideSolid {
  border-radius: 0!important;
  color: $whitePlain!important;
  background: $orangeActive!important;
  max-width: max-content!important;
  width: 100%!important;
  min-height: 48px!important;
  padding: 1rem 3rem 1rem 1.75rem!important;
  min-width: unset!important;
  transition: all 300ms ease-in-out!important;
  position: relative!important;
  border: $orangeActive 1px solid!important;
  line-height: 1.25!important;
  margin: 0!important;
  font-size: 1.28rem!important;
  font-weight: bold!important;
  letter-spacing: 0.5px!important;
  text-decoration: none!important;

  * {
    margin: 0!important;
    line-height: 1.25!important;
    font-size: 1.28rem!important;
    font-weight: bold!important;
    letter-spacing: 0.5px!important;
    text-decoration: none!important;
    color: $whitePlain!important;
  }

  img {
    display: none!important;
  }

  &:after {
    content: ''!important;
    display: block!important;
    position: absolute!important;
    right: 0.5rem!important;
    top: 50%!important;
    transform: translate3d(0,-50%,0)!important;
    width: 1.35rem!important;
    height: 1.35rem!important;
    background-size: contain!important;
    background-repeat: no-repeat!important;
    transition: transform 300ms ease-in-out!important;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCAxMiAyOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wLjYzMzcxNSAwLjMwNzE4NkMxLjMyNDI4IC0wLjIxMDg2NyAyLjI5NDYxIC0wLjA1ODE1MjMgMi44MDEwMiAwLjY0ODI4NEwxMS42OTk4IDEzLjA2MkMxMi4xMDAxIDEzLjYyMDMgMTIuMTAwMSAxNC4zNzk3IDExLjY5OTggMTQuOTM4TDIuODAxMDIgMjcuMzUxN0MyLjI5NDYxIDI4LjA1ODIgMS4zMjQyOCAyOC4yMTA5IDAuNjMzNzE1IDI3LjY5MjhDLTAuMDU2ODQ1NCAyNy4xNzQ4IC0wLjIwNjEyOCAyNi4xODIxIDAuMzAwMjgzIDI1LjQ3NTdMOC41MjY2NiAxNEwwLjMwMDI4MyAyLjUyNDMyQy0wLjIwNjEyOCAxLjgxNzg4IC0wLjA1Njg0NTQgMC44MjUyMzkgMC42MzM3MTUgMC4zMDcxODZaIiBmaWxsPSIjREM1ODJFIi8+Cjwvc3ZnPgo=")!important;
    filter: brightness(100);
  }

  svg {
    display: none!important;
  }
  &:hover {
    background: $orangeDeep!important;
    &:after {
      transform: translate3d(.25rem,-50%,0)!important;
    }
  }
  &:active {
    transition: transform 50ms ease-in-out!important;
    transform: translate3d(0,2px,0)!important;
  }

  &.newStyleButtonOverrideWhite {
    color: $whitePlain!important;
    background: #FFFFFF00!important;
    border: $whitePlain 1px solid!important;

    &:after {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCAxMiAyOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wLjYzMzcxNSAwLjMwNzE4NkMxLjMyNDI4IC0wLjIxMDg2NyAyLjI5NDYxIC0wLjA1ODE1MjMgMi44MDEwMiAwLjY0ODI4NEwxMS42OTk4IDEzLjA2MkMxMi4xMDAxIDEzLjYyMDMgMTIuMTAwMSAxNC4zNzk3IDExLjY5OTggMTQuOTM4TDIuODAxMDIgMjcuMzUxN0MyLjI5NDYxIDI4LjA1ODIgMS4zMjQyOCAyOC4yMTA5IDAuNjMzNzE1IDI3LjY5MjhDLTAuMDU2ODQ1NCAyNy4xNzQ4IC0wLjIwNjEyOCAyNi4xODIxIDAuMzAwMjgzIDI1LjQ3NTdMOC41MjY2NiAxNEwwLjMwMDI4MyAyLjUyNDMyQy0wLjIwNjEyOCAxLjgxNzg4IC0wLjA1Njg0NTQgMC44MjUyMzkgMC42MzM3MTUgMC4zMDcxODZaIiBmaWxsPSIjRkZGIi8+Cjwvc3ZnPgo=")!important;
    }

    &:hover {
      background: #FFFFFF10!important;
    }
  }
}

.xl-mr-auto {
  @media (min-width: 1281px) {
    margin-right: auto!important;
  }
}

// strange Overrides
html {
  .searchForm {
    position: relative;
    margin: 1.5rem 0;
    display: flex;
    max-width: 100%;
    border: 0!important;

    input {
      width: 100%;
      max-width: 400px;
      height: 48px;
      padding: 1rem 0 1rem 1rem!important;
      border: 2px solid $orangeDeep!important;
      border-right: none!important;
      border-radius: 0;
      outline: none;
      background-color: white;
      background-image: unset;
      background-repeat: no-repeat;
      color: $blueDeep;
      font-size: 18px;
      font-style: italic;
      line-height: $searchInputHeight - (4px * 2);

      @include mobile {
        border-color: $orangeDeep;
        font-size: 18px;
      }

      @include lt-medium {
        max-width: unset;
      }

      &::placeholder {
        color: $placeholderColor;
      }
    }

    button {
      display: flex;
      height: 48px;
      width: 48px;
      border: 2px solid $orangeDeep!important;
      border-left: 0!important;
      transition: all 300ms ease-in-out;
      background: $whitePlain!important;
      background-color: $whitePlain!important;
      position: relative!important;
      cursor: pointer;
      border-radius: 0!important;
      max-width: 48px!important;

      &:after {
        content: '';
        display: block;
        position: absolute;
        right: 0.5rem;
        top: 50%;
        transform: translate3d(0,-50%,0);
        width: 1.35rem;
        height: 1.35rem;
        background-size: contain;
        background-repeat: no-repeat;
        transition: transform 300ms ease-in-out;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCAxMiAyOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wLjYzMzcxNSAwLjMwNzE4NkMxLjMyNDI4IC0wLjIxMDg2NyAyLjI5NDYxIC0wLjA1ODE1MjMgMi44MDEwMiAwLjY0ODI4NEwxMS42OTk4IDEzLjA2MkMxMi4xMDAxIDEzLjYyMDMgMTIuMTAwMSAxNC4zNzk3IDExLjY5OTggMTQuOTM4TDIuODAxMDIgMjcuMzUxN0MyLjI5NDYxIDI4LjA1ODIgMS4zMjQyOCAyOC4yMTA5IDAuNjMzNzE1IDI3LjY5MjhDLTAuMDU2ODQ1NCAyNy4xNzQ4IC0wLjIwNjEyOCAyNi4xODIxIDAuMzAwMjgzIDI1LjQ3NTdMOC41MjY2NiAxNEwwLjMwMDI4MyAyLjUyNDMyQy0wLjIwNjEyOCAxLjgxNzg4IC0wLjA1Njg0NTQgMC44MjUyMzkgMC42MzM3MTUgMC4zMDcxODZaIiBmaWxsPSIjREM1ODJFIi8+Cjwvc3ZnPgo=");
      }

      &:hover {
        background: $whitePlain!important;
        &:after {
          transform: translate3d(.25rem,-50%,0);
        }
      }
    }
  }
}

h1.heroH1.twoPart {
  display: flex;
  flex-direction: column;
  span {
    text-transform: uppercase;
    display: block;
    @include styleH2;
  }
}

.override-bg-teal {
  background: $tealLight!important;
}

.override-bg-blue {
  background: $blueLight!important;
}

.override-bg-teal-signature {
  div[data-hero-background] {
    svg {
      background-image: linear-gradient(2deg, rgba(99,177,188,.1) 0%, rgba(99,177,188,1) 100%)!important;
      fill: $tealActive!important;
    }
  }

  &.heroLocation {
    h1,
    .heroHeadline {
      color: $tealDeep!important;
    }
  }
}

.override-bg-blue-signature {
  div[data-hero-background] {
    svg {
      background-image: radial-gradient(circle, rgba(92,139,185,.1) 0%, rgba(92,139,185,1) 80%);
      fill: $blueActive;
    }
  }

  &.heroLocation {
    h1,
    .heroHeadline {
      color: $blueDeep!important;
    }
  }
}

.override-bg-stone-signature {
  div[data-hero-background] {
    svg {
      background-image: radial-gradient(circle, rgba(214,210,196,.1) 0%, rgba(214,210,196,1) 80%);
      fill: $stoneActive;
    }
  }

  &.heroLocation {
    h1,
    .heroHeadline {
      color: $stoneDeep!important;
    }
  }
}

.container {
  padding-left: 2rem!important;
  padding-right: 2rem!important;

  @media only screen and (min-width: 992px) {
    padding-left: 4rem!important;
    padding-right: 4rem!important;
  }
}
